<template>
  <div class="wechatIcon" @click="jumpConsult">
    <img :src="fw" class="fixedIcon" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave"/>
    <div v-if="showQrcode" class="popover">
      <img :src="qrcode" />
    </div>
  </div>
</template>

<script>
import { isLogin } from "@/utils/jurisdiction";
import qrcode from './qrcode.jpg';
import fw from './fw.png';

export default {
  name: "FixedJumpCard",
  data() {
    return {
      fixedIcon: require("@/assets/home/fixed.png"),
      showQrcode: false,
      qrcode: qrcode,
      fw: fw,
    };
  },
  methods: {
    jumpConsult() {
      // if (isLogin()) {
      //   this.$router.push({
      //     path: "/business/consult",
      //   });
      // } else {
      //   this.$router.push("/account/login");
      // }
    },
    handleMouseEnter() {
      this.showQrcode = true;
    },
    handleMouseLeave() {
      this.showQrcode = false;
    }
  },
};
</script>

<style scoped lang="less">
.wechatIcon {
  position: fixed;
  width: 84px;
  height: 84px;
  right: 60px;
  top: 640px;
  cursor: pointer;
  z-index: 999;
  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}
.fixedIcon{
  width: 200px;
  height: 200px;
  background-color: #fff;
}
.popover {
  width: 200px;
  height: 200px;
  position: absolute;
  left: -200px;
  top: -70px;
  box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.1);
}
</style>
