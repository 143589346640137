<template>
  <el-dialog
    :title="title"
    :visible="visible"
    width="700px"
    @close="$emit('update:visible', false)"
  >
    <div class="container">
      <privacy v-if="protocolType == 2" />
      <disclaimer v-else-if="protocolType == 3" />
      <zh v-else-if="protocolType == 1" />
    </div>
  </el-dialog>
</template>

<script>
import ru from "./ru"
import en from "./en"
import zh from "./zh"
import disclaimer from "./disclaimer"
import privacy from "./privacy"

export default {
  name: "Agreement",
  components: {
    zh,
    ru,
    en,
    privacy,
    disclaimer
  },
  props: {
    protocolType: {
      type: Number,
      default: 2
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      title: '用户协议'
    }
  },
  watch: {
    protocolType(newVal) {
      if (newVal === 1) {
        this.title = "用户协议"
      }
      if (newVal === 2) {
        this.title = "隐私协议"
      }
      if (newVal === 3) {
        this.title = "免责声明"
      }
    }
  },
}
</script>

<style scoped>
.container {
  height: 600px;
  overflow-y: scroll;
  p{
    padding-top: 6px;
    line-height: 18pt;
  }
}
</style>
