import coded from './json/en.json';
import enLocale from 'element-ui/lib/locale/lang/en';
const en = {
  ...coded,
  ...enLocale,
  common: {
    pageList: [
      'Home',
      'Service ',
      'Query',
      'products',
      'results',
      [ 'zone', 'exchanges and cooperation', 'business consulting' ],
      // 'Forum',
      // 'News',
      // 'Q&A',
      // 'Smart Law',
      'About us',
    ],
    sign: 'Sign In',
    register: 'Register',
    platform: 'Introduction',
    guide: 'Guidance for settling in',
    rules: 'Service Rules',
    contact: 'Contact Us',
    address: 'Address:No. 1, Changjiang Road, SCO Demonstration Zone, Jiaozhou City, Qingdao City, Shandong Province',
    telephone: 'Tel: 15210715463',
    fax: 'Fax: 0532-55591980',
    email: 'E-mail: shfazhigu@163.com',
    organization: 'Administrative Committee of China - Shanghai Cooperation Organization Local Economic and Trade Cooperation Demonstration Zone',
  },
  home: {
    header: {
      titlle: 'Mega Data Platform for Foreign Legal Service',
      des: 'Mega Data Platform for Foreign Legal Service of Countries along the "One Belt One Road" ',
      searchTypeData: [
        'Legislations',
        'Legal Cases',
      ],
      selPla: 'Russia',
      inputPla: [ 'Enter Key words of laws and legislations', '请输入司法案例关键词' ]
    },
    navList: [ 'Lawyer', 'Arbitration', 'Mediation', 'Notarization', 'Legal Expertizing', 'Legal Aid' ],
    navListDes1: [ 'Check out law firms and', 'Understand the arbitration', 'Find out how to mediate', 'Find out how to apply', 'Find out about', 'How to seek legal aid query' ],
    navListDes2: [ 'lawyers for service guides', 'process and structure', 'with the COMMITTEE', 'for a notary office', 'forensic expertise', 'the national legal aid center' ],
    legalNeedsInfo: {
      title: 'Requirements',
      des: 'simpler submission of requirements ',
    },
    legalAidInfo: {
      title: 'Legal Service',
      des: 'more convenient  of search for help',
    },
    des1List: [ 'agencies', 'deals', '', 'articles', 'articles' ],
    des2List: [ 'Agency settling in', 'Trade volume', 'Rate of Successful Deal', 'Legal articles', 'Legal cases' ],
  },
  service: {
    selectionList: [ 'service sector', 'Foreign countries' ],
    bodyNav: [ 'Smart Law' ]
  },
  legalQuery: {
    leftNavBar: [ 'Publishing department', 'Regulations category', 'Year published' ]
  }
  // service: {
  //   header: {
  //     list: ['Foreign-related government affairs area', 'Legal requirements', 'Legal service']
  //   },
  //   navList: ['Find a lawyer', 'Ask for Arbitration', 'Find mediation', 'Apply for notarization', 'Find identification', 'Seek legal aid'],
  //   searchTypeNameList: ['Law office', 'Lawyer'],
  //   searchPla: ['Enter keywords of law office', 'Enter keywords of lawyer', 'Please enter keywords for arbitration agency', 'Please enter keywords for mediation agency', 'Enter keywords of notary office', 'Please enter the keywords of the appraisal agency', 'Enter keywords of Assistance Center'],
  //   searchName: 'Search',
  //   selectionList: ['Selected conditions', 'country', 'Provinces', 'Downtown area', 'Sequence', 'Involved regions', 'Demand Type', 'Status', 'Service type'],
  //   releaseDemand: 'Publishing Requirement',
  //   demandPla: '请输入法律服务关键词',
  //   releaseDesk: 'Publishing Service',
  //   deskPla: '请输入法律需求关键词',
  // },
  // account: {
  //   register: {
  //     commonForm: {
  //       lable: {
  //         userName: '用户名：',
  //         phone: '手机号：',
  //         graphical: '图形验证码：',
  //         short: '手机验证码：',
  //         email: '电子邮箱：',
  //         password: '登录密码：',
  //         confirmPassword: '确认密码：',
  //         abroad: {
  //           userName: 'User Name1：',
  //           phone: 'Phone Number：',
  //           graphical: 'Captcha：',
  //           short: 'E-mail verification code：',
  //           email: 'E-mail：',
  //           password: 'Password：',
  //           confirmPassword: 'Confirm password：',
  //           agreement: '',
  //           shortTips: 'Get code',
  //         }
  //       },
  //       pla: {
  //         userName: '请输入用户名',
  //         phone: '请输入手机号',
  //         graphical: '请输入验证码',
  //         short: '请输入手机验证码',
  //         email: '请输入电子邮箱',
  //         password: '请设置8-20个字符，区分大小写',
  //         confirmPassword: '请再次输入密码',
  //       }
  //     }
  //   }
  // },
};
export default en;
