<template>
  <div class="content">
    <p>涉外法律服务大数据平台免责声明</p>
    <p>
      任何直接或间接（包括但不限于通过站外API接入等其它形式）使用“涉外法律服务大数据平台”网站服务或数据的行为，都将被视作已无条件接受本声明所涉全部内容或服务；若使用人（包括“涉外法律服务大数据平台”网站注册用户）对本声明（包括但不限于免责条款、知识产权、隐私政策）的任何条款有异议，请立即停止使用“涉外法律服务大数据平台”所提供的全部内容和服务。
    </p>
    <p>1.免责条款</p>
    <p>
      “涉外法律服务大数据平台”（www.scolegal.com）上的内容，包括但不限于企业咨询、专家回复、出版物、新闻资讯、培训会议等，所包含的信息或观点仅供参考，不代表上合“法智谷”知晓其内容之存在、赞同或认可该信息或观点、或者证明其内容的真实性，不供予作为投资或交易的决定或其他商务决策所依赖的依据。信息提供方或信息来源应对其发布的内容负责，因所发布的任何内容侵犯任何第三方任何权利的，由该信息提供方或信息来源依法承担全部责任。“涉外法律服务大数据平台”对上述内容予以免责。
    </p>
    <p>
      用户在“涉外法律服务大数据平台”发布侵犯他人知识产权、名誉权或其他合法权益的内容，或发布损害国家利益、违背现行法律法规及相关政策、违背公序良俗的相关内容时，“涉外法律服务大数据平台”有权对相应内容采取屏蔽、删除等处理措施，并保留移交司法机关或行政机关处理的权利。“涉外法律服务大数据平台”对上述内容的具体处理方式以法律要求的义务为限，“涉外法律服务大数据平台”不因未履行超出法律规定之义务而承担责任。
    </p>
    <p>
      本网站对采集的信息在编辑整理时力求准确，但本网站不对信息的真实性、准确性、有效性、可靠性做出任何形式的保证，基于此信息而产生的任何法律后果与本网站无关。网站访问者因使用或无法使用本网站提供的信息所造成的直接或间接损失，本网站不承担任何责任。
    </p>
    <p>
      本网站包含有第三方网站的链接，但本网站不对任何第三方网站上的内容承担任何责任，亦不对第三方网站内容做出任何的认可。无论在任何原因下（包括但不限于疏忽原因），对您通过使用与本网站链接的网站信息所导致的损失或损害，责任均由使用者自行承担（包括但不限于疏忽责任）。
    </p>
    <p>
      本网站所提供信息中的所有数据及分析仅供研究参考之用，如与政府或权威部门发布的数据不一致，请以权威发布数据为准。
    </p>
    <p>
      本网站上的内容可随时发生变更，恕不事先通知。本网站或内容提供方对于发布的内容中存在的任何错误、不准确、延迟更新、或任何因依赖于本网站发布的内容而采取的任何行动，均不承担任何责任。本网站保留纠正网站内容的权利，但不保证该等纠正是准确的或者完整的。
    </p>
    <p>建议使用谷歌、IE8.0 及以上版本浏览本站。</p>
    <p>2. 知识产权</p>
    <p>
      “涉外法律服务大数据平台”所登载发布的一切内容，包括但不限于文字、图片、音像、图表、标志、标识、广告、商标、域名、软件、程序、版面设计、专栏目录与名称、内容分类标准以及为使用者提供的任何信息，均受《中华人民共和国著作权法》《中华人民共和国商标法》《中华人民共和国专利法》等法律法规以及有关国际条约的保护，均由权利所有方（内容提供方、本网站或原作者等）所有。
    </p>
    <p>
      因用户发布内容引发知识产权纠纷的，用户须自行承担相应后果，若因此造成“涉外法律服务大数据平台”损失的，“涉外法律服务大数据平台”有权依照法律规定维护合法权益。
    </p>
    <p>
      “涉外法律服务大数据平台”展示的“涉外法律服务大数据平台”标识、版面设计、排版方式、文本、图片、图形等受著作权法、商标法、专利法及其它法律保护的部分，未经“涉外法律服务大数据平台”书面同意，不得在任何其他平台发布、使用，或出于发布、使用目的的改编、修改，或用于其他商业及非商业目的。
    </p>
    <p>
      用户可以下载本网站上显示的资料，但这些资料只限于个人学习使用，未经权利人书面授权许可，任何机构、个人不得对本网站提供的信息和服务内容作任何商业或非商业目的之使用，不得擅自转载、复制、出版、翻译、或改写本网站的信息和服务内容，如需转载，必须取得权利人合法授权，否则本网站将依法追究侵权者的法律责任。
    </p>
    <p>
      “涉外法律服务大数据平台”不对用户发布内容的知识产权权属进行保证，并且尊重权利人的知识产权及其他合法权益。若权利人认为“涉外法律服务大数据平台”用户发布的内容侵犯自身的知识产权及其他合法权益，可依法向“涉外法律服务大数据平台”发出书面通知，并应提供具有证明效力的证据材料。“涉外法律服务大数据平台”在书面审核相关材料后，有权根据《中华人民共和国侵权责任法》《信息网络传播权保护条例》等法律法规，在不事先通知相应发布用户的情况下自行删除相关内容。
    </p>
    <p>
      “涉外法律服务大数据平台”根据上述条款删除相关内容的，相应发布用户如存在异议，可依法向“涉外法律服务大数据平台”发出关于被删除内容不侵权的书面说明，并提供具有证明效力的证据材料。“涉外法律服务大数据平台”在书面审核后，将根据实际情况依法进行处理。
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.content {
  p {
    padding-top: 6px;
    line-height: 18pt;
  }
}
</style>
