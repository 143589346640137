<template>
  <div class="content">
    <title>涉外法律服务大数据平台隐私政策</title>
  <p>涉外法律服务大数据平台隐私政策</p>
  <p>一、引言</p>
  <p>
    欢迎使用涉外法律服务大数据平台（以下简称“本平台”）。我们深知个人信息对您的重要性，您的信赖对我们非常重要。我们将严格遵守法律法规要求，采取相应的安全保护措施，致力于保护您的个人信息安全可控。基于此，我们制定本《隐私政策》，帮助您充分了解在您使用本平台服务的过程中，我们会如何收集、使用、共享、存储和保护您的个人信息以及您可以如何管理您的个人信息，以便您更好地作出适当的选择。
  </p>
  <p>二、收集和使用个人信息</p>
  <p>（一）收集个人信息的目的</p>
  <p>
    提供服务：为了向您提供涉外法律服务，包括但不限于法律咨询、案例查询、风险评估等，我们需要收集您的个人信息。
  </p>
  <p>
    优化服务：通过收集和分析您的使用行为，我们能够不断优化平台功能，提升用户体验。
  </p>
  <p>
    合规要求：为了满足相关法律法规的要求，确保平台的合法合规运营，我们可能需要收集特定的个人信息。
  </p>
  <p>（二）收集的个人信息类型</p>
  <p>
    注册信息：当您注册成为本平台用户时，我们将收集您的姓名、电子邮箱、联系电话等基本信息。
  </p>
  <p>
    使用信息：在您使用本平台服务过程中，我们会收集您的浏览记录、搜索关键词、咨询记录等信息。
  </p>
  <p>
    设备信息：为了保障平台的安全性和稳定性，我们可能会收集您的设备型号、操作系统版本、IP地址等信息。
  </p>
  <p>（三）使用个人信息的方式</p>
  <p>
    提供服务：我们将使用您的个人信息为您提供个性化的法律服务，如推荐相关案例、解答法律问题等。
  </p>
  <p>
    数据分析：通过分析您的使用行为，我们能够了解用户需求，优化平台功能和服务。
  </p>
  <p>
    安全保护：我们可能会使用您的个人信息进行安全检测和风险评估，以防止欺诈和其他非法活动。
  </p>
  <p>三、共享、转让和公开披露个人信息</p>
  <p>（一）共享</p>
  <p>
    服务提供商：为了提供更好的服务，我们可能会与第三方服务提供商共享您的个人信息，但我们会要求他们按照我们的要求和相关法律法规保护您的个人信息。
  </p>
  <p>
    法律要求：在法律要求的情况下，我们可能会与政府机构或其他相关方共享您的个人信息。
  </p>
  <p>（二）转让</p>
  <p>
    业务转让：如果本平台发生合并、收购或破产清算等情况，您的个人信息可能会被转让给新的所有者，但我们会要求他们继续遵守本隐私政策。
  </p>
  <p>（三）公开披露</p>
  <p>法律要求：在法律要求的情况下，我们可能会公开披露您的个人信息。</p>
  <p>四、个人信息的保护</p>
  <p>
    安全措施：我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。
  </p>
  <p>
    数据保留：我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。
  </p>
  <p>
    安全事件应对：在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知相关情况，并采取合理措施进行处理。
  </p>
  <p>五、您的权利</p>
  <p>（一）访问您的个人信息</p>
  <p>
    您有权访问您的个人信息，法律法规规定的例外情况除外。如果您想行使数据访问权，可以通过以下方式自行访问。
  </p>
  <p>（二）更正您的个人信息</p>
  <p>当您发现我们处理的关于您的个人信息有错误时，您有权要求我们作出更正。</p>
  <p>（三）删除您的个人信息</p>
  <p>在以下情形中，您可以向我们提出删除个人信息的请求：</p>
  <p>如果我们处理个人信息的行为违反法律法规；</p>
  <p>如果我们收集、使用您的个人信息，却未征得您的同意；</p>
  <p>如果我们处理个人信息的行为违反了与您的约定；</p>
  <p>如果您不再使用我们的产品或服务，或您注销了账号；</p>
  <p>如果我们不再为您提供产品或服务。</p>
  <p>（四）改变您授权同意的范围</p>
  <p>对于额外收集的个人信息的收集和使用，您可以随时给予或收回您的授权同意。</p>
  <p>（五）个人信息主体注销账户</p>
  <p>
    您随时可注销此前注册的账户，我们将停止为您提供产品或服务，并依据您的要求，删除您的个人信息，法律法规另有规定的除外。
  </p>
  <p>六、联系我们</p>
  <p>
    如果您对本隐私政策有任何疑问、意见或建议，您可以通过以下方式与我们联系：
  </p>
  <p>电子邮箱：shfazhigu@163.com</p>
  <p>联系电话：15210715463</p>
  <p>通讯地址：山东省青岛市胶州市上合示范区长江路1号</p>
  <p>我们将尽快回复您的请求，并为您提供必要的帮助。</p>
  <p>
    请注意，本隐私政策可能会根据法律法规的变化和业务发展的需要进行更新。我们会在平台显著位置发布更新后的隐私政策，并通知您相关变化。
  </p>
  <p>感谢您对涉外法律服务大数据平台的信任与支持！</p>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.content {
  p{
    padding-top: 6px;
    line-height: 18pt;
  }
}
</style>
