
import coded from './json/zh.json';
import zhLocale from 'element-ui/lib/locale/lang/zh-CN';

const zh = {
  ...coded,
  ...zhLocale,
  common: {
    pageList: [
      '首页',
      '服务大厅',
      '法律查询',
      '法律服务产品',
      '法律研究成果',
      [ '交流合作基地', '交流合作', '业务咨询' ],
      // '法律智库',
      // '资讯动态',
      // '业务咨询',
      // '智慧法务',
      '关于我们',
    ],
    sign: '登录',
    register: '免费注册',
    platform: '平台介绍',
    guide: '入驻指南',
    rules: '服务规则',
    contact: '联系我们',
    address: '地址：山东省青岛市胶州市上合示范区长江路1号',
    telephone: '咨询电话：15210715463',
    fax: '传真：0532-55591980',
    email: '邮箱：shfazhigu@163.com',
    organization: '中国-上海合作组织地方经贸合作示范区管理委员会',
  },
  home: {
    header: {
      titlle: '涉外法律服务大数据平台',
      des: '“一带一路”沿线国家涉外法律服务大数据平台',
      searchTypeData: [
        '法律法规',
        '司法案例',
      ],
      selPla: '请选择',
      inputPla: [ '请输入法律法规关键词', '请输入司法案例关键词' ]
    },
    navList: [ '请律师', '要仲裁', '找调解', '办公证', '寻鉴定', '求法援' ],
    navListDes1: [ '查找律所和律师', '查找仲裁机构', '查找调委会', '查找公证机构', '查找司法鉴定机构', '查询全国法援中心' ],
    navListDes2: [ '了解服务指南', '了解仲裁流程', '了解如何进行调解', '了解如何办证', '了解司法鉴定', '了解如何寻求法援' ],
    legalNeedsInfo: {
      title: '法律需求',
      des: '发需求·更简单',
    },
    legalAidInfo: {
      title: '法律服务',
      des: '找服务·更省心',
    },
    des1List: [ '家', '笔', '', '条', '条' ],
    des2List: [ '机构入驻', '交易量', '对接成功率', '法律条文', '司法案例' ],
  },
  service: {
    selectionList: [ '服务领域', '涉外国家' ],
    bodyNav: [ '智慧法务' ]
  },
  legalQuery: {
    leftNavBar: [ '发布部门', '法规类别', '发布年份' ]
  }
  // service: {
  //   header: {
  //     list: ['涉外政务专区', '法律需求', '法律服务']
  //   },
  //   navList: ['请律师', '要仲裁', '找调解', '办公证', '寻鉴定', '求法援'],
  //   searchTypeNameList: ['律师事务所', '律师'],
  //   searchPla: ['请输入律所关键词', '请输入律师关键词', '请输入仲裁机构关键词', '请输入调解机构关键词', '请输入公证处关键词', '请输入鉴定机构关键词', '请输入法援中心关键词'],
  //   searchName: '搜 索',
  //   selectionList: ['已选条件', '国家', '省市', '市区', '排序', '涉及区域', '需求类型', '状态', '服务类型'],
  //   releaseDemand: '发布需求',
  //   demandPla: '请输入法律服务关键词',
  //   releaseDesk: '发布服务',
  //   deskPla: '请输入法律需求关键词',
  //
  // },
  // account: {
  //   register: {
  //     com: {
  //       nextStep: '下一步',
  //       lastStep: '上一步',
  //       step2: '填写账号信息',
  //       step3: '完成',
  //       enterprise: {
  //         title: '企业注册',
  //         step1: '验证企业'
  //       },
  //       organization: {
  //         title: '机构注册',
  //         step1: '验证机构'
  //       },
  //       lawyer: {
  //         title: '律师注册',
  //         step1: '验证律师'
  //       },
  //       personal: {
  //         title: '个人注册',
  //         step1: ''
  //       },
  //       abroad: {
  //         nextStep: 'Next Step',
  //         lastStep: 'Last step',
  //         step2: 'Fill in account information',
  //         step3: 'Complete',
  //         enterprise: {
  //           title: 'Business registration',
  //           step1: 'Company Verification'
  //         },
  //         organization: {
  //           title: 'Institution Registration',
  //           step1: 'Agency Certification'
  //         },
  //         lawyer: {
  //           title: 'Lawyer registration',
  //           step1: 'Lawyer Verification'
  //         },
  //         personal: {
  //           title: 'Individual registration',
  //           step1: ''
  //         },
  //       },
  //     },
  //     category: {
  //       enterprise: '企业注册',
  //       personal: '个人注册',
  //       organization: '机构注册',
  //       lawyer: '律师注册',
  //       abroad: {
  //         enterprise: 'Business registration',
  //         personal: 'Individual Registration',
  //         organization: 'Institution Registration',
  //         lawyer: 'Lawyer registration',
  //       }
  //     },
  //     commonForm: {
  //       lable: {
  //         userName: '用户名：',
  //         phone: '手机号：',
  //         graphical: '图形验证码：',
  //         short: '手机验证码：',
  //         email: '电子邮箱：',
  //         password: '登录密码：',
  //         confirmPassword: '确认密码：',
  //         agreement: '我已阅读并同意',
  //         agreement1: '《涉外法律服务大数据平台用户注册协议》',
  //         shortTips: '发送验证码',
  //         abroad: {
  //           userName: 'User Name：',
  //           phone: 'Phone Number：',
  //           graphical: 'Captcha：',
  //           short: 'E-mail verification code：',
  //           email: 'E-mail：',
  //           password: 'Password：',
  //           confirmPassword: 'Confirm password：',
  //           agreement: 'I have read and agree to the',
  //           agreement1: '<Mega Data Platform for Foreign Legal Service User Registration Agreement>',
  //           shortTips: 'Get code',
  //         }
  //       },
  //       pla: {
  //         userName: '请设置用户名',
  //         userName1: '请输入5-20个字符字母、数字或下划线',
  //         phone: '请输入手机号',
  //         graphical: '请输入验证码',
  //         short: '请输入短信验证码',
  //         email: '请输入电子邮箱',
  //         password: '请设置8-20个字符登录密码',
  //         confirmPassword: '请再次输入密码',
  //         agreement: '请同意注册协议',
  //         abroad: {
  //           userName: 'Set the user name',
  //           phone: 'Enter phone number',
  //           graphical: 'Enter verification code',
  //           short: 'Enter verification code',
  //           email: 'Enter e-mail',
  //           password: 'Set the password',
  //           confirmPassword: 'Enter your password again',
  //           agreement: '请同意注册协议',
  //         }
  //       }
  //     },
  //     EnterpriseForm: {
  //       lable: {
  //         name: '企业名称：',
  //         creditCode: '统一社会信用代码：',
  //         businessLicense: '营业执照：',
  //         province: '企业所在地：',
  //         city: '',
  //         address: '详细地址：',
  //         establishedTime: '成立时间：',
  //         industryId: '所属行业：',
  //         scale: '企业规模：',
  //         abroad: {
  //           name: 'Company Name：',
  //           creditCode: 'Unified ID：',
  //           businessLicense: 'Business License：',
  //           // province: '企业所在地：',
  //           // city: '',
  //           address: 'Company location：',
  //           establishedTime: 'Established Date：',
  //           industryId: 'Industry：',
  //           scale: 'enterprise scale：',
  //         }
  //       },
  //       pla: {
  //         name: '请输入营业执照上的公司名称',
  //         creditCode: '请输入营业执照上的社会信用代码',
  //         businessLicense: '请上传企业营业执照',
  //         province: '请选择',
  //         city: '请选择',
  //         address: '请输入企业详细地址',
  //         establishedTime: '请选择企业成立时间',
  //         industryId: '请选择所属行业',
  //         scale: '请选择企业规模',
  //         abroad: {
  //           name: 'Please enter the company name on your business license',
  //           creditCode: 'Please input the social credit code on the business license',
  //           businessLicense: 'Please upload the business license',
  //           // province: '请选择',
  //           // city: '请选择',
  //           address: 'Please enter the full address',
  //           establishedTime: 'Please select the established date',
  //           industryId: 'Select Industry',
  //           scale: 'Please select enterprise size',
  //         }
  //       }
  //     },
  //     organizationForm: {
  //       lable: {
  //         organizationType: '机构类型：',
  //         name: '机构名称：',
  //         legalPerson: '机构负责人：',
  //         idCard: '法人身份证号：',
  //         creditCode: '统一社会信用代码：',
  //         province: '机构所在地：',
  //         city: '',
  //         address: '详细地址：',
  //         establishedTime: '成立时间：',
  //         abroad: {
  //           organizationType: 'Organization Type：',
  //           name: 'Agency Name：',
  //           legalPerson: 'Agency Responsible Person：',
  //           idCard: 'Responsible Person‘s ID：',
  //           creditCode: 'Unified Social Credit Code：',
  //           // province: 'Agency Location：',
  //           // city: '',
  //           address: 'Agency Location：',
  //           establishedTime: 'Established Date：',
  //         }
  //       },
  //       pla: {
  //         organizationType: '请输入机构名称',
  //         name: '请输入机构名称',
  //         legalPerson: '请输入机构负责人',
  //         idCard: '请输入法人身份证号',
  //         creditCode: '请输入社会信用代码',
  //         province: '请选择',
  //         city: '请选择',
  //         address: '请输入机构详细地址',
  //         establishedTime: '请选择成立时间',
  //         abroad: {
  //           organizationType: 'Please enter the name of the organization',
  //           name: 'Please enter the agency name',
  //           legalPerson: 'Please enter agency responsible person',
  //           idCard: 'Please enter the responsible person‘s ID',
  //           creditCode: 'Please enter the unified social credit code',
  //           // province: '请选择',
  //           // city: '请选择',
  //           address: 'Please enter the full address',
  //           establishedTime: 'Please select the established date',
  //         }
  //       }
  //     },
  //     lawyerForm: {
  //       lable: {
  //         name: '律师姓名：',
  //         idCard: '律师身份证号：',
  //         licenseNumber: '律师执业证号：',
  //         organizationName: '所属律所：',
  //         field: '擅长领域：',
  //         abroad: {
  //           name: 'Lawyer’s Name：',
  //           idCard: ' Lawyers ID：',
  //           licenseNumber: 'Lawyers License NO：',
  //           organizationName: 'Law Firm：',
  //           field: 'Legal Areas of Expertise：',
  //         },
  //       },
  //       pla: {
  //         name: '请输入律师姓名',
  //         idCard: '请输入律师身份证号',
  //         licenseNumber: '请输入律师执业证号',
  //         organizationName: '请选择或输入所属律所',
  //         field: '请选择擅长领域',
  //         abroad: {
  //           name: 'Please enter lawyers name',
  //           idCard: 'Please enter the lawyer‘s ID',
  //           licenseNumber: 'Please enter the lawyers license number',
  //           organizationName: 'Please select your law firm',
  //           field: 'Choose your area of expertise',
  //         },
  //       }
  //     }
  //
  //   }
  // },
  // content: {
  //   request: {
  //     manage: {
  //
  //     },
  //     cooperation: {
  //
  //     }
  //   }
  // },
  // about: {
  //   title: '关于我们',
  //   bar: ['平台介绍', '入驻指南', '联系我们']
  // },
  // think: {
  //   title: '法律智库',
  //   bar: ['专家访谈', '法律论坛', '研究成果', '研究机构']
  // },
};
export default zh;
