<template>
  <div class="footer">
    <div class="container">
      <div class="introduce">
        <!-- <router-link
          class="uiText"
          to="/about/intro"
        >{{
          $t("common.platform")
        }}</router-link> -->
        <!-- <div class="interval"></div> -->
        <router-link
          class="uiText"
          to="/about/rules"
        >{{
          $t("common.rules")
        }}</router-link>
        <div class="interval"></div>
        <router-link
          class="uiText"
          to="/about/contact"
        >{{
          $t("common.contact")
        }}</router-link>
        <!-- <div class="interval"></div>
        <router-link class="uiText" to="/">{{
          $t('common.complaint')
        }}</router-link> -->
      </div>
      <div class="dividingLine"></div>
      <div class="info">
        <div class="uiText">{{ $t("common.address") }}</div>
        <div class="uiText">{{ $t("common.telephone") }}</div>
        <!-- <div class="uiText">{{ $t("common.fax") }}</div> -->
        <div>{{ $t("common.email") }}</div>
      </div>
      <div class="name">{{ $t("common.organization") }}</div>
      <div class="bt">
        <span class="lf">ICP备案号: 鲁ICP备2021010800号-1</span>
        <a style="color:#fff" href="https://beian.mps.gov.cn/#/query/webSearch?code=37028102001640" rel="noreferrer" target="_blank" class="lf">
          公安备案号: <img class="img" :src="police" /><span>鲁公网安备37028102001640号</span>
        </a>
        <span class="rt">
          <span
            v-for="(item,index) of list"
            :key="index"
            class="btn"
            @click="showAgreement(index+1)"
          >
            {{ item }}
          </span>
        </span>
      </div>
    </div>
    <Protocol
      :visible.sync="visible"
      :protocol-type="type"
    />
  </div>
</template>

<script>

import Protocol from "@/components/RegisterAgreement/protocol"
import police from "@/assets/common/police.png"

export default {
  name: "Footer",
  components: {
    Protocol,
  },
  data() {
    return {
      police: police,
      visible: false,
      type: 1,
      list: ['用户协议', '隐私协议', '免责声明']
    }
  },
  methods: {
    showAgreement(type) {
      this.visible = true
      this.type = type
    },
  },
}
</script>

<style scoped lang="less">
@import "~@/styles/variables.less";
.footer {
  width: 100%;
  // height: 180px;
  background: #111b40;
  color: @uni-text-color;
  .img{
    width: 18px;
    height: 18px;
    margin-right: 3px;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: @uni-width;
    margin: 0 auto;

    .introduce {
      display: flex;
      align-items: center;
      height: 64px;

      .interval {
        width: 1px;
        height: 10px;
        margin: 0 30px;
        background: #ffffff;
        border-radius: 1px;
        opacity: 0.7;
      }

      .uiText {
        color: @uni-text-color;
      }
    }

    .dividingLine {
      width: 100%;
      height: 1px;
      background: #ffffff;
      opacity: 0.2;
    }

    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 52px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);

      .uiText {
        margin-right: 25px;
        white-space: nowrap;
      }
    }

    .name {
      height: 62px;
      line-height: 62px;
    }
    .bt {
      padding: 0 0 30px 0;
      // height: 62px;
      .lf {
        margin-right: 40px;
      }
      .btn {
        cursor: pointer;
        margin-right: 15px;
      }
    }
  }
}
</style>
